<template>
    <v-main app class="login-remember">
        <v-container fluid class="d-flex">
            <v-alert v-for="alert in alerts" :type="alert.type" :key="alert.type + '_' + alert.content">
                {{ alert.content }}
            </v-alert>
            <v-form ref="form" @submit="handleSubmit" class="pa-8 elevation-5 rounded-lg">

                <v-row align="center" justify="center">
                    <v-col cols="6" class="pt-0">
                        <h1 class="font-weight-bold text-h5 mb-0">Login</h1>
                    </v-col>
                    <v-col cols="6" class="pt-0 text-right">
                        <v-btn
                            color="accent"
                            class="px-4 text-uppercase white--text"
                            rounded
                            href="/download/qfconnector"
                        >
                            Learn More
                            <v-icon
                                right
                                dark
                            >
                                mdi-help-circle
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <label for="login-email" class="font-weight-bold text-subtitle-2">Email</label>
                        <v-text-field
                            placeholder="example@example.com"
                            v-model="formData.email"
                            type="email"
                            :error-messages="validationErrors.email"
                            required
                            single-line
                            hide-details="auto"
                            flat
                            solo
                            dense
                            background-color="background"
                            id="login-email"
                            class="rounded-0"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <label for="login-password" class="font-weight-bold text-subtitle-2">Password</label>
                        <v-text-field
                            placeholder="password"
                            v-model="formData.password"
                            type="password"
                            :error-messages="validationErrors.password"
                            required
                            single-line
                            hide-details="auto"
                            flat
                            solo
                            dense
                            background-color="background"
                            id="login-password"
                            class="rounded-0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <div class="forgot-password mt-3">
                    <router-link :to="{name:'auth.forgot-password'}" >
                        Forgotten Password? <span class="font-weight-bold">Click here</span>
                    </router-link>
                </div>
                <div class="text-right">
                    <v-btn type="submit" depressed rounded class="mt-4 px-7 text-uppercase white--text" color="primary">
                        {{ processing ? "Please wait" : "Login" }}
                    </v-btn>
                </div>
            </v-form>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions } from 'vuex';
import authActionTypes from "@/store/modules/auth/action-types";
import router from "@/router";

export default {
    data: () => ({
        processing: false,
        valid: true,
        formData: {
            email: '',
            password: '',
        },
        validationErrors: {},
        alerts: [],
    }),
    methods: {
        ...mapActions({
            signIn: authActionTypes.LOGIN.PATH,
        }),
        handleSubmit(event) {
            event.preventDefault();
            this.processing = true;
            this.validationErrors = {};
            axios.post('/login', this.formData)
                .then(({ data }) => {
                    this.signIn().then(() => {
                        router.push({
                            name: this.$store.state.auth.routes.login_successful,
                        });
                    });
                })
                .catch(({ response: { data } }) => {
                    if(data.message !== undefined) {
                        this.alerts = [{
                            type: 'error',
                            content: data.message,
                        }];
                    }
                    if(data.errors !== undefined) {
                        this.validationErrors = data.errors;
                    }
                })
                .finally(() => {
                    this.processing = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">

.v-form {
    max-height: 416px;
}

</style>
