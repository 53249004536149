import axios from 'axios';
import router from "@/router";
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: {
            roles: [],
            permissions: [],
        },
        roles: {}, // All roles, not just those of the current user
        permissions: {}, // All permissions, not just those of the current user
        routes: {
            login_successful: 'dashboard.home',
        },
        nav_items : []
    },
    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user
        },
    },
    mutations: {
        [MUTATION_TYPES.SET_AUTHENTICATED](state, value) {
            state.authenticated = value
        },
        [MUTATION_TYPES.SET_USER](state, value) {
            state.user = value
        },
        [MUTATION_TYPES.SET_ROLES](state, value) {
            state.roles = value
        },
        [MUTATION_TYPES.SET_PERMISSIONS](state, value) {
            state.permissions = value
        },
        [MUTATION_TYPES.SET_NAV_ITEMS](state, value) {
            state.nav_items = value;
        },  
    },
    actions: {
        [ACTION_TYPES.LOGIN.NAME]({ commit, state, dispatch }) {
            return new Promise((resolvePromise, rejectPromise) => {
                axios.get('/api/user')
                    .then(({ data }) => {
                        commit(MUTATION_TYPES.SET_USER, data);
                        commit(MUTATION_TYPES.SET_AUTHENTICATED, true);


                        axios.get('/api/roles-permissions/all')
                            .then(({ data }) => {
                                commit(MUTATION_TYPES.SET_ROLES, data.roles);
                                commit(MUTATION_TYPES.SET_PERMISSIONS, data.permissions);
                                commit(MUTATION_TYPES.SET_NAV_ITEMS, 
                                    [
                                        { title: 'FAQs', icon: 'mdi-frequently-asked-questions', link: {name: 'dashboard.faqs'}, permission: state.permissions.FAQ_READ_WEB.name },
                                        { title: 'Places', icon: 'mdi-map-marker', link: {name: 'dashboard.places.index'}, permission: state.permissions.PLACE_READ_WEB.name },
                                        { title: 'Accreditations', icon: 'mdi-book-open', link: {name: 'dashboard.accreditations.index'}, permission: state.permissions.ACCREDITATION_READ_WEB.name },
                                        { title: 'Testimonials', icon: 'mdi-message-draw', link: {name: 'dashboard.testimonials.index'}, permission: state.permissions.TESTIMONIAL_READ_WEB.name },
                                        { title: "Trusted By's", icon: 'mdi-head-sync', link: {name: 'dashboard.figureheads.index'}, permission: state.permissions.FIGUREHEAD_READ_WEB.name }
                                    ]
                                );
                                
                            });

                        resolvePromise();
                    })
                    .catch(() => {
                        rejectPromise();
                        dispatch(ACTION_TYPES.LOGOUT.NAME);
                    });
            });
        },
        [ACTION_TYPES.LOGOUT.NAME]({ commit }) {
            commit(MUTATION_TYPES.SET_USER, {});
            commit(MUTATION_TYPES.SET_AUTHENTICATED, false);
            router.push({
                name: 'auth.login',
            });
        },
    },
};
